// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-curriculum-resources-ai-revolution-js": () => import("./../../../src/pages/curriculum-resources/ai-revolution.js" /* webpackChunkName: "component---src-pages-curriculum-resources-ai-revolution-js" */),
  "component---src-pages-curriculum-resources-energy-and-energy-transfer-js": () => import("./../../../src/pages/curriculum-resources/energy-and-energy-transfer.js" /* webpackChunkName: "component---src-pages-curriculum-resources-energy-and-energy-transfer-js" */),
  "component---src-pages-curriculum-resources-energy-and-forces-js": () => import("./../../../src/pages/curriculum-resources/energy-and-forces.js" /* webpackChunkName: "component---src-pages-curriculum-resources-energy-and-forces-js" */),
  "component---src-pages-curriculum-resources-forces-of-motion-js": () => import("./../../../src/pages/curriculum-resources/forces-of-motion.js" /* webpackChunkName: "component---src-pages-curriculum-resources-forces-of-motion-js" */),
  "component---src-pages-curriculum-resources-stability-and-instability-js": () => import("./../../../src/pages/curriculum-resources/stability-and-instability.js" /* webpackChunkName: "component---src-pages-curriculum-resources-stability-and-instability-js" */),
  "component---src-pages-curriculum-resources-types-of-interactions-js": () => import("./../../../src/pages/curriculum-resources/types-of-interactions.js" /* webpackChunkName: "component---src-pages-curriculum-resources-types-of-interactions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-career-profile-js": () => import("./../../../src/templates/careerProfile.js" /* webpackChunkName: "component---src-templates-career-profile-js" */)
}

